import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionWhoWeAre from "../components/sectionWhoWeAre.js"

const PageWhoWeAre = () => {
  return (
    <Layout>
      <SEO title="3 Leaps LLC - Who We Are"/>
      <SectionWhoWeAre/>
    </Layout>
  )
}
export default PageWhoWeAre
