import React, {useState} from "react"
import PropTypes from "prop-types"

import { Box, Heading, Accordion, AccordionPanel, Grid, Markdown, ResponsiveContext, Text, Paragraph } from "grommet"
import { graphql, useStaticQuery } from "gatsby"
import ImageSharpImage from "../components/imageHandling/imageSharpImage"
import ResponsiveGrid from "../components/responsiveGrid"
import BoxDivider from "./boxDivider"
import Layout from "./layout"

const testContent = `          Our network of industry veterans have deep expertise in solution consulting, enterprise architecture, decision science, applied math, large-scale data flow management, business intelligence, payment, fulfillment, and other key disciplines in the transactional business ecosystem.<br>

          ###[Contact us](/contact-by-message) to discuss your needs
          
          ###Explore [how we can help](/how-we-can-help)
`
/*
 * Note on query - for support on mobile phone the bios are split into two parts and put in separate areas
 * in a ResponsiveGrid.  I haven't figured out how to flow an image if we use the gatsby-image package
 * This gives an approximate benefit by putting some of the text to right of image and some below in smaller configs
 */
const whoWeAreSectionQuery = graphql`
query WhoWeAreSectionQuery {
  brandMetadata: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/company-info/"}}, sort: {fields: fileAbsolutePath, order: ASC}) {
    edges {
      node {
        id
        rawMarkdownBody
        fileAbsolutePath
        excerpt
        html
        frontmatter {
          title
          tag
        }
      }
    }
  }
  whoWeAre: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "\/who-we-are\/"}}, sort: {order: ASC, fields: fileAbsolutePath}) {
    edges {
      node {
        id
        rawMarkdownBody
        fileAbsolutePath
        excerpt
        html
        frontmatter {
          title
          tag
        }
      }
    }
  }
  site {
    siteMetadata {
      title
      siteUrl
      headline
      description
      mission
      twitter
      name
    }
  }
}
`

const SectionWhoWeAre = (props) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null)
  /*
   * Now pull off the who-intro file so we can use in the top of box
   */
  const whoAllMarkdown = useStaticQuery(whoWeAreSectionQuery)
  if (!whoAllMarkdown) {
    console.log("Content retrieval error")
    return null
  }
  console.log('The query is ' + whoAllMarkdown + ' and whoweare is ' + whoAllMarkdown.whoWeAre)
  console.log('The edges are ' + JSON.stringify(whoAllMarkdown.whoWeAre.edges[0]))
  const whoIntroMarkdownFilterResult =
    whoAllMarkdown.whoWeAre.edges.filter(md => md.node.fileAbsolutePath.endsWith("who-intro.md"))
  console.log('The filter 1 is ' + whoIntroMarkdownFilterResult)

  const whoIntroMarkdownFilterIntro =
    whoAllMarkdown.whoWeAre.edges.filter(md => md.node.fileAbsolutePath.endsWith("who-intro.md"))[0].node

  const whoIntroMarkdownFilterBioThompson =
    whoAllMarkdown.whoWeAre.edges.filter(md => md.node.fileAbsolutePath.includes("who-bio-thompson"))

  const brandMetadataMarkdownPageHeader=
    whoAllMarkdown.brandMetadata.edges.filter(md => md.node.frontmatter.tag === "how-we-help")

  const brandMetadataMarkdownMission=
    whoAllMarkdown.brandMetadata.edges.filter(md => md.node.frontmatter.tag === "mission")

  console.log('The metadata = ' + brandMetadataMarkdownPageHeader)

  // length will be 0 if the filename is wrong!
  console.log("The length of whointro is " + whoIntroMarkdownFilterResult.length)
  if (whoIntroMarkdownFilterResult.length != 1) {
    console.log("ERROR: did not retrieve the single entry for markdown who intro file")
    return null
  }

  const whoIntroMarkdown = whoIntroMarkdownFilterResult[0].node

  // a simple panel header
  const renderAccordionTitle = (title) => {
    return (
      <Heading level={2}>{title}</Heading>
    )
  }

  /*
   *  CSS notes for parapgraphs - have found these props to not have an effect, other than the size one
   *  Probably still don't understand how to get them to apply - see the inside of components for Markdown
   *  , "props": {"overflow": "hidden", "white-space": "nowrap", "text-overflow": "..."}
   *  In ResponsiveGrid, not sure alignContents is doing anything
   *
   * DET FIXME - since we can't wrap, aren't really worrying about a middle case where some of bio is next
   *      to image and some is below
   * At this point there is no reason to have the bio split in two pieces.
   */
  return (
    <Box fill background={{"color": props.backgroundColor}}>
      <Grid
        // important to use row=fit so we don't get extra padding
        rows={["fit", "flex", "flex"]}
        columns={["flex"]}
        areas={[
          { name: 'message', start: [0,0], end: [0, 0] },
          { name: 'biographies', start: [0,1], end: [0,1] },
          { name: 'calltoaction', start: [0,2], end: [0,2] },
        ]}
        //fill={true}
      >
        <Box
          gridArea={"message"}
          background={{"color": props.backgroundColor}}
        >
          <ImageSharpImage
            imageIdentifier={"CARD-HERO-MAIN-TAGLINE"}
          />
        </Box>
        <Box
          gridArea={"biographies"}
          direction={"row-responsive"}
          fill
          background={{"color": props.backgroundColor}}
        >
          <Box
            // use alignSelf center tp float with text to left and don't use align to make image appear
            fill={false}
            alignSelf={"center"}
            height={{max: "large"}}
            width={{min: "medium"}}
            margin={{left: "small", right: "small", bottom: "small"}}
          >
            <ImageSharpImage
              imageIdentifier={"HEADSHOT-THOMPSON-2"}
            />
          </Box>
          <Box
            alignSelf={"center"}
            margin={{horizontal: "medium", top: "small", bottom: "small"}}
            width={{max: "large"}}
            overflow={"visible"}
            border={false}
          >
            <Markdown
              components={{ p: (props) => <Paragraph {...props} fill /> }}
            >
              {whoIntroMarkdownFilterBioThompson[0].node.rawMarkdownBody}
            </Markdown>
            <Markdown
              components={{ p: (props) => <Paragraph {...props} fill /> }}
            >
              {whoIntroMarkdownFilterBioThompson[1].node.rawMarkdownBody}
            </Markdown>
          </Box>
        </Box>
        <Accordion
          gridArea={"calltoaction"}
          multiple={false}
          margin={{top: "small"}}
          // remember this is an array even if multiple set to false (length 1 in that case)
          active={activeAccordionIndex}
          onActive={newIndex => setActiveAccordionIndex(newIndex)}
        >
          <AccordionPanel label={renderAccordionTitle("Our Business")}>
            <Box
              as={"intro"}
              pad={{"top": "small", "bottom": "small"}}
              margin={{horizontal: "small"}}
              background={{"color": props.backgroundColor}}
            >
              <Markdown
                components={{ p: (props) => <Paragraph {...props} fill/> }}
              >
                {whoIntroMarkdownFilterIntro.rawMarkdownBody}
              </Markdown>
            </Box>
          </AccordionPanel>
          <AccordionPanel label={renderAccordionTitle("Our Mission")}>
            <Box
              as={"intro"}
              pad={{"top": "small", "bottom": "small"}}
              margin={{horizontal: "small"}}
              background={{"color": props.backgroundColor}}
            >
              <Markdown
                components={{ p: (props) => <Paragraph {...props} fill/> }}
              >
                {brandMetadataMarkdownMission[0].node.rawMarkdownBody}
              </Markdown>
            </Box>
          </AccordionPanel>
        </Accordion>
      </Grid>
    </Box>
  )
}

SectionWhoWeAre.propTypes = {
  backgroundColor: PropTypes.string
}
SectionWhoWeAre.defaultProps = {
  backgroundColor: "light-2"
}

export default SectionWhoWeAre
